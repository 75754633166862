<template>
  <div ref="Container">
    <canvas ref="Chart" class="fill-all"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      COLORS: this.$vuetify.theme.themes.light,
      chartEl: null
    };
  },

  computed: {
    chartData() {
      return {
        labels: ['今日總交易額', '今日商戶入款', '今日提現總額'],
        datasets: [
          {
            type: 'bar',
            label: '營業額(萬)',
            yAxisID: 'turnover',
            data: this.data,
            borderColor: this.COLORS.Primary600,
            backgroundColor: this.COLORS.Primary600
          }
        ]
      };
    }
  },

  watch: {
    chartData() {
      if (!this.chartEl) this.renderChart();
      this.chartEl.data = this.chartData;

      this.$nextTick(() => {
        this.chartEl.update();
      });
    }
  },

  methods: {
    renderChart() {
      const config = {
        type: 'line',
        data: this.chartData,
        options: {
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true
              }
            }
          },
          scales: {
            xAxis: {
              ticks: {
                color: this.COLORS.Primary600
              }
            },
            turnover: {
              stacked: true,
              ticks: {
                color: this.COLORS.Primary600
              },
              type: 'linear',
              position: 'left'
            }
          }
        }
      };

      const ctx = this.$refs.Chart.getContext('2d');
      this.chartEl = new Chart(ctx, config);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
