<template>
  <div>
    <v-card-text class="text-Heading6">首頁</v-card-text>
    <v-card outlined>
      <Async :loading="isFetching" remain-content>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="auto">
              <v-select
                rounded
                label="累計總額"
                v-model="summaryOption"
                :items="summaryOptions"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="平台總成交額"
                :subtitle="result.summary.total_transaction_amt | currency"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="總成交訂單數"
                :subtitle="result.summary.total_done_orders"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                :title="`實際收入總額\n（含手動加款）`"
                :subtitle="result.summary.total_actual_income | currency"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="累積提現總和"
                :subtitle="result.summary.total_withdrawal | currency"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="商戶資金餘額"
                :subtitle="result.summary.total_merchant_balance | currency"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="今日成交金額"
                :subtitle="result.summary.today_transaction_amt | currency"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                title="今日成交訂單數量"
                :subtitle="result.summary.today_done_orders"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <TitleWithLine
                :title="`今日實際收入\n（含手動加款）`"
                :subtitle="result.summary.today_actual_income | currency"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </Async>
    </v-card>

    <SizeBox height="16" />

    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <Async :loading="isChartFetching" remain-content>
              <div class="d-flex">
                <span class="text-DisplayRegular Secondary800--text">
                  營收獲利報表
                </span>

                <v-spacer></v-spacer>

                <v-btn-toggle
                  v-model="chartOptionWeeks"
                  color="Primary600"
                  rounded
                  mandatory
                >
                  <v-btn small :value="1">近七日</v-btn>
                  <v-btn small :value="2">前兩週</v-btn>
                  <v-btn small :value="3">前三週</v-btn>
                </v-btn-toggle>
              </div>
              <SizeBox height="38" />
              <StackedBarLine :data="result.chartData" />
            </Async>
          </v-col>

          <v-col cols="12" sm="6">
            <Async :loading="isFetching" remain-content>
              <div class="d-flex">
                <span class="text-DisplayRegular Secondary800--text">
                  營收獲利報表
                </span>

                <v-spacer></v-spacer>
              </div>
              <SizeBox height="38" />
              <BarChart :data="profitData" />
            </Async>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getSystemIndexSummary, getHistorySummaryGraph } from '@/api/reports';

import BarChart from '../components/charts/BarChart.vue';
import StackedBarLine from '../components/charts/StackedBarLine.vue';

export default {
  components: { StackedBarLine, BarChart },
  data() {
    return {
      isFetching: false,
      isChartFetching: false,
      result: {
        summary: {
          total_transaction_amt: 0, // 平台總成交額
          total_done_orders: 0, // 總成交訂單數
          total_actual_income: 0, // 實際收入總額（包含手動加款）
          total_withdrawal: 0, // 累積提現總和
          total_merchant_balance: 0, // 商戶資金餘額
          today_transaction_amt: 0, // 今日成交金額 chart
          today_done_orders: 0, // 今日成交訂單數量
          today_actual_income: 0, // 今日實際收入（包含手動加款）
          today_withdrawal: 0, // 今日提現金額 chart
          today_topup_amt: 0 // 今日商戶入款 chart
        },
        chartData: {
          date: [],
          profit: [],
          revenue: []
        }
      },
      summaryOption: 'total',
      summaryOptions: [
        { text: '累計總額', value: 'total' },
        { text: '近 7 日累計', value: 'week' },
        { text: '近 30 日累計', value: 'month' },
        { text: '近 1 年累計', value: 'year' }
      ],
      chartOptionWeeks: 1
    };
  },

  computed: {
    profitData() {
      return [
        this.result.summary.today_transaction_amt,
        this.result.summary.today_topup_amt,
        this.result.summary.today_withdrawal
      ];
    }
  },

  watch: {
    summaryOption() {
      this.fetchData();
    },
    chartOptionWeeks() {
      this.fetchChartData();
    }
  },

  created() {
    this.fetchData();
    this.fetchChartData();
  },

  methods: {
    async fetchData() {
      this.isFetching = true;
      const Sum = await getSystemIndexSummary({
        time_frame: this.summaryOption
      });
      this.$set(this.result, 'summary', Sum);

      this.isFetching = false;
    },
    async fetchChartData() {
      this.isChartFetching = true;
      const Data = await getHistorySummaryGraph({
        days: this.chartOptionWeeks * 7
      });
      this.$set(this.result, 'chartData', Data);

      this.isChartFetching = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
